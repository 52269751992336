
$(document).ready(function(){
//     $('.banner').slick({
//         infinite: true,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 6000,
//         prevArrow: '<a href="javascript:;" class="mdi mdi-chevron-left gallery_arrows left"></a>',
//         nextArrow: '<a href="javascript:;" class="mdi mdi-chevron-right gallery_arrows right"></a>'
//     });
    if(Cookies.get('routeSubscription') !== undefined && BladeHelper.isLogged)
    {
        var route = Cookies.get('routeSubscription');
        Cookies.remove('routeSubscription');
        window.location.replace(route);
    }
});

$('.btn_menu_mobile').on('click' , function(){
    $(this).toggleClass('active');
    $('.listaNav').toggleClass('active');
    $('.menu').toggleClass('active');
    $('.menu ul').slideToggle(200);
});

$('.click_log_menu').on('click' , function() {
    $('.sub_menu_logado').slideToggle(200);
    $('.sub_menu_logado').toggleClass('active');
    $('.mdi-chevron-down').toggleClass('active');
});

$('[data-remember-login]').on('click', function () {
    $(this).parents('.modal').modal('hide');
    swal({
        title: "Recuperar senha",
        text: "Digite seu e-mail:",
        type: "input",
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
        inputPlaceholder: "seuemail@email.com"
    },
    function(inputValue){
        if (inputValue === false)
            return false;
        if (inputValue === "") {

            swal.showInputError("Informe seu e-mail!");
            return false
        }

        $.ajax({
            url: '/recuperar/senha',
            type: 'GET',
            data: {email: inputValue},
        })
        .done(function(data) {
            swal(data.title, data.message, data.type);
        })
        .fail(function(data) {
            swal("Ooops!", "Aconteceu um erro, tente novamente mais tarde", "error");
            console.log("error");
        });

    });
});

var form = $('#example-form');

form.children("div").steps({
    headerTag: "h3",
    bodyTag: "section",
    transitionEffect: "slideLeft",
    labels: {
        next: "Próximo",
        previous: "Anterior",
        finish: "Finalizar",
    },
    onStepChanging: function (event, currentIndex, newIndex)
    {
        form.validate().settings.ignore = ":disabled,:hidden";
        return form.valid();
    },
    onFinishing: function (event, currentIndex)
    {
        form.validate().settings.ignore = ":disabled,:hidden";
        return form.valid();
    },
    onFinished: function (event, currentIndex)
    {
        form.submit();
    }
});

function initMap() {

    var destiny_address = BladeHelper.address;

    geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': destiny_address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            var position = results[0].geometry.location;
            latlng = new google.maps.LatLng(position.d, position.e);

            var mapDiv = document.getElementById('map');

            var map = new google.maps.Map(mapDiv, {
                center: latlng,
                zoom: 16,
                scrollwheel: false,
                zoomControl: true,
                scaleControl: true,
                center: position,
            });

            var marker = new google.maps.Marker({
                map: map,
                position: map.getCenter(),
                title: 'Ágere Soluções'
            });

            map.set('styles',
                [
                    {
                        "featureType": "all",
                        "stylers": [
                            {
                                "saturation": 0
                            },
                            {
                                "hue": "#e7ecf0"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "stylers": [
                            {
                                "saturation": -70
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "saturation": -60
                            }
                        ]
                    }
                ]
            )
        } else {
            alert('Endereço não localizado no Google Maps');
        }
    });
//
};

$(document).on('click', '#myButton', function() {
    $.blockUI();
});


  //habilitar campos no form
    $(function () {
        $('.options_questions').hide();

        //show it when the checkbox is clicked
        $('input[name="person_type"]').on('click', function () {
            if ($('.legal_person').prop('checked')) {
                $('.options_questions').fadeIn();
                $('input[name="age"]').attr('required', 'required');
            } else {
                $('.options_questions').hide();
                $('input[name="age"]').removeAttr('required');
            }
        });
    });


    // ---- tabs modal ---- //

    $("#yes").click(function(){
        $(".confirma_cadastro").hide();
    });


    $("#no").click(function(){
        $(".confirma_cadastro").hide();
        $("#cadastro").toggleClass('active');
        $("#tab_cadastro").toggleClass('active');
        $("#login").removeClass('active');
        $("#tab_login").removeClass('active');
    });

    $("#tab_login").click(function(){
        $("#tab_cadastro").removeClass('active');
    });

    $("[data-login-register]").on('click', function () {
        $(".login-modal").modal('show');
    });

    $("#btn_login").click(function(){
        $("#tab_login").addClass('active');
        $("#login").addClass('active');
        $("#tab_cadastro").removeClass('active');
        $("#cadastro").removeClass('active');
        $("#register_login").addClass("hide");
    });

    $("#btn_cadastro").click(function(){
        $("#tab_cadastro").addClass('active');
        $("#cadastro").addClass('active');
        $("#tab_login").removeClass('active');
        $("#login").removeClass('active');
        $("#register_login").addClass("hide");
    });

    $("#btn_register_training").click(function () {
        $("#register_login").removeClass("hide");
        Cookies.set('routeSubscription', $(this).data('login-register'));
    });

    $('select[name="typeLogin"]').on('change', function () {
        $('[data-form-login]').addClass('hide');
        if($(this).val() == 'siga')
            $('[data-form-login="siga"]').removeClass('hide');
        if($(this).val() == 'webmatic')
            $('[data-form-login="webmatic"]').removeClass('hide');
        if($(this).val() == 'site')
            $('[data-form-login="site"]').removeClass('hide');
    });


   // ---- tabs modal ---- //

    var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        loop: true,
        autoplay: 10000,
    });
