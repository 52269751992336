var GucoHelpers = function () {
    var initInputMask = function () {
        var nineDigitsMask = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 0 0000-0000' : '(00) 0000-00009';
        };

        nineDigitsOptions = {
            placeholder: "(__) _ ____-____",
            clearIfNotMatch: true,
            onKeyPress: function(val, e, field, options) {
                field.mask(nineDigitsMask.apply({}, arguments), options);
            }
        };
        $('[data-mask-date]').mask('00/00/0000', {placeholder: "__/__/____", clearIfNotMatch: true});
        $('[data-mask-time]').mask('00:00', {placeholder: "__:__", clearIfNotMatch: true});
        $('[data-mask-year]').mask('0000', {placeholder: "____", clearIfNotMatch: true});
        $('[data-mask-date_time]').mask('00/00/0000 00:00:00', {placeholder: "__/__/____ __:__:__", clearIfNotMatch: true});
        $('[data-mask-phone]').mask('(00) 0000-0000', {placeholder: "(__) ____-____", clearIfNotMatch: true});
        $('[data-mask-phone9]').mask(nineDigitsMask, nineDigitsOptions);
        $('[data-mask-postal-code]').mask('00.000-000', {placeholder: "__.___-___", clearIfNotMatch: true});
        $('[data-mask-cpf]').mask('000.000.000-00', {reverse: true, placeholder: "___.___.___-__", clearIfNotMatch: true});
        $('[data-mask-money]').mask("#.##0,00", {reverse: true, placeholder: "_,__", clearIfNotMatch: true});
        $("[data-mask-document]").mask('00.000.000/0000-00', {placeholder: "__.___.___/____-__", clearIfNotMatch: true});
        $('[data-mask-percent]').mask('#.##0,00%', {reverse: true, placeholder: "_,__%", clearIfNotMatch: true});
    }

    return {
        init: function () {
            initInputMask();
        }
    }
}();
